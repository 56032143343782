<template>
    <div class="pa-3">
        <div class="pa-3 white rounded">
            <v-autocomplete :value="storeId" @input="setStoreId" :items="stores" placeholder="Store to deliver" flat hide-details solo-inverted 
                item-text="name" item-value="id" @change="setShippingStore" :readonly="store != null" ref="storeNum"
                :filter="pickupFilter" @keydown.enter="select" :search-input.sync="query">
                <template v-slot:item="{ item }">
                    {{ item.name }} [{{ item.wintuxID }}]
                </template>
            </v-autocomplete>
        </div>

        <v-card flat class="mt-3" :loading="loadingView">
            <v-card-text>
                <v-row v-if="store != null">
                    <v-col cols="6">
                        <div class="headline">{{ store.name }}</div>

                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <th>Code</th>
                                    <td>{{ store.wintuxID }}</td>
                                </tr>
                                <tr>
                                    <th>Phone</th>
                                    <td>{{ formatPhoneNum( store.phone ) }}</td>
                                </tr>
                                <tr>
                                    <th>Route</th>
                                    <td>{{ store.route }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>

                        <div class="subtitle-1 my-3">Total Orders</div>

                        <div class="display-1">
                            {{ orders.length }}
                        </div>
                    </v-col>

                    <v-col cols="6">
                        <div class="subtitle-1 mb-3">Orders to be Ship</div>

                        <v-text-field v-model="orderNum" placeholder="Scan order" flat hide-details solo-inverted 
                            v-mask="'XXX-#########'" @keydown.enter="scanOrderNum" ref="orderNum" @blur="onBlur" class="mb-3"></v-text-field>

                        <v-simple-table dense>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Order Num
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="order in orders">
                                    <tr :key="order.id">
                                        <td>{{ order.orderNum }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions v-if="store != null">
                <v-btn depressed @click="resetDialog = true">Reset</v-btn>
                <v-spacer></v-spacer>
                <v-btn depressed color="grey darken-4" class="white--text" :disabled="orders.length == 0" @click="warningDialog = true">Continue</v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="shoesDialog" width="400" persistent>
            <v-card :loading="assigningShoes">
                <v-card-title>Assign shoes</v-card-title>
                <v-card-subtitle>Please assign the shoes to this order</v-card-subtitle>
                <v-card-text>
                    <v-text-field v-model="barcode" placeholder="Scan shoes" flat hide-details solo-inverted 
                        v-mask="'############'" @keydown.enter="assignShoes" :disabled="assigningShoes" ref="barcode"></v-text-field>

                    <v-list v-if="shoes != null">
                        <v-list-item>
                            <v-list-item-avatar v-if="shoes.imgSrc != undefined && shoes.imgSrc != null">
                                <v-img :src="shoes.imgSrc" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <div>{{ shoes.productName }}</div>
                                <div class="caption grey--text">{{ shoes.category }}</div>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div class="grey--text">{{ shoes.foreignId }}</div>
                                <div class="grey--text">{{ shoesSize }}</div>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed @click="setPendingShoes">Set as pending</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn depressed color="grey darken-4" class="white--text" :disabled="assigningShoes" @click="cancelShoes">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="resetDialog" width="400" persistent>
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to reset? All your work will be lost.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="resetDialog = false">No</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" @click="cleanForm(); resetDialog = false;">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="warningDialog" width="400" persistent>
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to set the status of these <strong>{{ orders.length }}</strong> orders to <i>Truck Line</i>?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="warningDialog = false">No</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" :loading="processing" @click="next">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <force-dialog v-model="forceDialog" :text="forceDialogText" :forcing="forcing" @force="forceItemToOrder" />
    </div>
</template>

<script>
import ForceDialog from '@/components/ForceDialog.vue';
import { mapGetters, mapActions } from 'vuex';
import { _st } from '@/softech';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        loadingView     : false,
        processing      : false,
        warningDialog   : false,
        resetDialog     : false,
        shoesDialog     : false,
        orderNum        : '',
        barcode         : '',
        assigningShoes  : false,
        forceDialogText : '',
        forceDialog     : false,
        forcing         : false,

        tempOrder       : null,

        query           : ''
    }),
    computed: {
        ...mapGetters({
            stores  : 'pickupLocations/items',
            storeId : 'stations/truckLine/storeId',
            store   : 'stations/truckLine/store',
            orders  : 'stations/truckLine/orders',
        }),
        shoes() {
            if( this.tempOrder == null ) return null;
            return this.tempOrder.items.find(i => i.categoryId == 'SH');
        },
        shoesSize() {
            if( this.tempOrder == null ) return null;

            let m = this.tempOrder.measurements;
            return `${m.shoeSize}${m.shoeType}`;
        }
    },
    mounted() {
        this.init();

        this.$nextTick(() => {
            if( this.store != null )
                this.$refs.orderNum.focus();
        });
    },
    methods: {
        ...mapActions({
            loadStores          : 'pickupLocations/load',
            setStoreId          : 'stations/truckLine/setStoreId',
            setStore            : 'stations/truckLine/setStore',
            cleanForm           : 'stations/truckLine/clear',
            scanOrder           : 'stations/truckLine/scanOrder',
            process             : 'stations/truckLine/process',
            assignShoesToOrder  : 'stations/truckLine/assignShoes',
            forceShoesToOrder   : 'stations/truckLine/forceShoes'
        }),
        async init() {
            try {
                await this.loadStores();
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
            }
        },
        setShippingStore() {
            let s = this.stores.find(i => i.id == this.storeId);
            this.setStore(s);

            setTimeout(() => { this.$refs.orderNum.focus(); }, 500);
        },
        async scanOrderNum() {
            this.playBeep2();

            let found = this.orders.find(i => i.orderNum == this.orderNum);
            if( found !== undefined ) {
                this.mxShowMessage('Order is already in the list.', 'warning');
                this.orderNum = '';
                return;
            }

            this.tempOrder = await this.scanOrder( this.orderNum );
            this.orderNum = '';

            // if order is null then the order has all items assigned including the shoes
            if( this.tempOrder == null )
                return;

            // ask for the shoes before adding the order to the list otherwise
            this.shoesDialog = true;
        },
        async next() {
            try {
                this.processing = true;

                await this.process();

                this.processing = false;
                this.warningDialog = false;

                this.mxShowMessage(`${this.orders.length} orders changed to Truck Line`, 'success');

                this.cleanForm();
            }
            catch(error) {
                this.processing = false;
                this.warningDialog = false;
            }
        },
        pickupFilter(item, queryText) {
            const text1 = item.name.toLowerCase();
            const text2 = item.wintuxID.toLowerCase();

            return text1.indexOf(queryText) > -1 || text2.indexOf(queryText) > -1
        },
        select() {
            let res = this.stores.find(s => s.wintuxID == this.query);

            if( _st.isNUE( res ) )
                return;

            this.setStoreId( res.id );
            this.setShippingStore();
            this.$refs.storeNum.blur();
        },
        async assignShoes() {
            try {
                this.playBeep();
                
                this.assigningShoes = true;
                let res = await this.assignShoesToOrder({ orderId: this.tempOrder.id, foreignId: this.tempOrder.foreignId, barcode: this.barcode });
                this.assigningShoes = false;

                if( res && res.canBeForced === true ) {
                    this.playWarning();
                    this.forceDialogText = res.text;
                    this.forceDialog = true;
                }

                this.shoesDialog = false;
                this.barcode = '';
            }
            catch(error) {
                this.assigningShoes = false;
                this.barcode = '';
                setTimeout(() => this.$refs.barcode.focus(), 100);
            }
        },
        async forceItemToOrder() {
            try {
                this.forcing = true;
                await this.forceShoesToOrder({ foreignId: this.tempOrder.foreignId });
                this.forcing = false;

                this.forceDialog = false;
                this.barcode = '';
            }
            catch(error) {
                this.forcing = false;
                this.barcode = '';
            }
        },
        setPendingShoes() {
            this.orders.push({ id: this.tempOrder.id, orderNum: this.tempOrder.foreignId });
            this.shoesDialog = false;
        },
        cancelShoes() {
            this.shoesDialog = false;
            this.tempOrder = null;
        },
        onBlur() {
            setTimeout(() => {
                if( this.store != null ) 
                    this.$refs.orderNum.focus();
            }, 100);
        },
    },
    components: { ForceDialog },
    directives: { mask }
}
</script>

<style lang="scss" scoped>

</style>